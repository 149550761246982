<style>
.audio-player-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.playback-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.time-container {
  position: relative;
}

.time {
  font-size: 80%;
  color: var(--primary-color-dark);
  white-space: nowrap;

  /* This is a clunky way to properly align the time indicators. Don't know
                                                                 how to do this otherwise without using absolute widths */
  position: absolute;
  transform: translateY(-50%);
}

.time.left {
  left: 0;
}

.time.frames {
  right: 0;
}

.icon-btn {
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: inherit;
  color: var(--primary-color-dark);
  height: 56px;
  width: 56px;
  margin: 4px;
}

.icon-btn:disabled {
  color: var(--primary-color);
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.loader {
  margin: 9px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid var(--primary-color);
  border-right: 5px solid var(--primary-color);
  border-bottom: 5px solid var(--primary-color);
  border-left: 5px solid var(--primary-color-dark);
  transform: translateZ(0);
  width: 46px;
  height: 46px;
  box-sizing: border-box;
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script lang="ts">
import BaseBubble from './BaseBubble.svelte';
import { analytics } from '../../analytics';
import Icon from '../game/Icon.svelte';

export let url = '';
export let answer = false;
export let read = false;
export let highlight = false;
export let showCaption = false;

const formatPlaybackTime = (seconds: number | null) => {
  // warning: completely strips out hours
  if (seconds === null || seconds === undefined) {
    return '00:00';
  }
  return new Date(seconds * 1000)?.toISOString().substr(14, 5);
};

let audioEl: HTMLAudioElement;
let currentTime = formatPlaybackTime(null);
let remainingTime = formatPlaybackTime(null);
let playing = false;
let loading = false;

const onError = () => {
  console.error(audioEl.error);
};

const updateTimes = () => {
  currentTime = formatPlaybackTime(Math.round(audioEl.currentTime));
  remainingTime = '-' + formatPlaybackTime(Math.round(audioEl.duration) - Math.round(audioEl.currentTime));
};

const playPause = () => {
  if (audioEl.paused) {
    audioEl.play();
    playing = true;
    analytics.events.audioPlay();
  } else {
    audioEl.pause();
    playing = false;
  }
};

const skip = (seconds: number) => () => {
  audioEl.currentTime += seconds;
};
const skipForward = skip(10);
const skipBack = skip(-10);

const setLoading = () => {
  loading = true;
};

const setReady = () => {
  loading = false;
};
</script>

<BaseBubble answer="{answer}" read="{read}" highlight="{highlight}" showCaption="{showCaption}">
  <audio
    preload="metadata"
    bind:this="{audioEl}"
    on:timeupdate="{updateTimes}"
    on:loadedmetadata="{updateTimes}"
    on:abort="{onError}"
    on:canplaythrough="{setReady}"
    on:waiting="{setLoading}"
    on:playing="{setReady}"
  >
    <track src="" />
    <source src="{url}" type="audio/mpeg" on:error="{onError}" />
    Your browser does not support the audio tag.
  </audio>
  <div class="audio-player-container">
    <div class="time-container" aria-label="afgespeelde tijd">
      <span class="time left">{currentTime}</span>
    </div>
    <div class="playback-controls">
      <button
        class="icon-btn control-button"
        on:click="{skipBack}"
        aria-label="10 seconden terug"
        disabled="{!playing || loading}"
      >
        <Icon name="replay_10_24dp" size="24px" />
      </button>
      {#if loading}
        <div class="loader"></div>
      {:else}
        <button
          class="icon-btn play-button"
          on:click="{playPause}"
          aria-label="{playing ? 'Pauze' : 'Play'}"
          disabled="{loading}"
        >
          <Icon name="{playing ? 'pause_circle_outline_24dp' : 'play_circle_filled_24dp'}" size="56px" />
        </button>
      {/if}
      <button
        class="icon-btn control-button"
        on:click="{skipForward}"
        aria-label="10 seconden verder"
        disabled="{!playing || loading}"
      >
        <Icon name="forward_10_24dp" size="24px" />
      </button>
    </div>
    <div class="time-container" aria-label="overblijvende tijd">
      <span class="time frames">{remainingTime}</span>
    </div>
  </div>
  <slot name="caption" slot="caption" />
</BaseBubble>
