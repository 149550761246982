const gameUrl = (locationId) => `db/${locationId}/game.json`;
/**
 * Fetches a `Game` object from the server.
 *
 * @throws Error If the server responded but the response was not ok (200) or if a
 * network error occurs (set `fetch()` documentation).
 *
 * @param locationId the id of the location to fetch.
 */
export const fetchGame = (locationId) => {
    return fetch(gameUrl(locationId))
        .then((response) => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
        .then((json) => json);
};
