export class DelayedQueue {
    constructor() {
        this.queue = [];
        this.currentJob = null;
        this._fastforward = false;
    }
    addTask(callback, delayMs) {
        this.queue.push({
            callback,
            delay: delayMs,
        });
        this.next();
    }
    next() {
        if (!this.isIdle()) {
            return;
        }
        const job = this.queue.shift();
        this.currentJob = job ? this.scheduleJob(job) : null;
    }
    scheduleJob(job) {
        if (this.fastForward) {
            job.callback();
            return null;
        }
        return {
            callback: job.callback,
            tHandle: window.setTimeout(() => {
                job.callback();
                this.currentJob = null;
                this.next();
            }, job.delay),
        };
    }
    isIdle() {
        return this.currentJob === null;
    }
    get fastForward() {
        return this._fastforward;
    }
    set fastForward(newVal) {
        this._fastforward = newVal;
        if (this._fastforward) {
            if (this.currentJob) {
                window.clearTimeout(this.currentJob.tHandle);
                this.currentJob.callback();
            }
        }
    }
    get hasPendingJobs() {
        return this.queue.length > 0 || this.currentJob !== null;
    }
}
