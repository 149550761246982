import { analytics } from './analytics';
import { writable } from 'svelte/store';
// Stores an ISO timestamp of the last time the user accepted cookie usage.
const LOCALSTORAGE_KEY_ACCEPT = 'last_accept_cookies';
let lastAcceptCookies = localStorage.getItem(LOCALSTORAGE_KEY_ACCEPT);
export let isGdprChecked = !!lastAcceptCookies;
export const setGdprChecked = (checked = true) => {
    if (checked) {
        lastAcceptCookies = new Date().toISOString();
        localStorage.setItem(LOCALSTORAGE_KEY_ACCEPT, lastAcceptCookies);
        analytics.enable();
    }
    else {
        lastAcceptCookies = null;
        localStorage.removeItem(LOCALSTORAGE_KEY_ACCEPT);
    }
    isGdprChecked = true;
    gdprStore.set(isGdprChecked);
};
if (isGdprChecked) {
    analytics.enable();
}
const gdprStore = writable(isGdprChecked);
export const gdpr = {
    isChecked: isGdprChecked,
    setChecked: setGdprChecked,
    subscribe: gdprStore.subscribe,
};
