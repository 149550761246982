<style>
section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: var(--grid-size);
}

.loading {
  align-self: center;
  margin-top: 64px;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 32px;
  height: 32px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

a.icon-link {
  display: flex;
  align-items: center;
}

a.icon-link > .icon {
  display: flex;
  margin-left: 4px;
}
</style>

<script lang="ts">
import { GameViewModel, Logo, Message, PodcastMessage, QrMessage, MessageType } from '../../game/GameViewModel';
import type { AnyMessage } from '../../game/GameViewModel';
import MessageBubble from '../bubbles/MessageBubble.svelte';
import LogoHeader from '../bubbles/LogoHeader.svelte';
import BubbleTransition from '../bubbles/BubbleTransition.svelte';
import { afterUpdate, onDestroy } from 'svelte';
import type { Unsubscriber } from 'svelte/types/runtime/store';
import QrBubble from '../bubbles/QrBubble.svelte';
import AudioplayerBubble from '../bubbles/AudioplayerBubble.svelte';
import Icon from './Icon.svelte';

export let game: GameViewModel;
let messages: AnyMessage[];
let section: HTMLElement;
let unsubscribe: Unsubscriber | undefined;

// This is really shitty but Svelte only allows auto-subscription stores as free variables.
// We can make this simpler if we require a game object to be known on init / don't make it dynamic
$: {
  unsubscribe && unsubscribe();

  unsubscribe = game?.messages.subscribe((current) => {
    messages = current;
  });
}

onDestroy(() => {
  unsubscribe && unsubscribe();
});

afterUpdate(() => {
  // Always scroll the last added bubble into view
  // Warning: this code doesn't check whether the change is actually an added
  // element --> could break when another kind of update is added in the future.
  if (section.lastElementChild) {
    section.lastElementChild.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
});
</script>

<section bind:this="{section}">
  {#if !game}
    <div class="loading">
      <div class="lds-dual-ring"></div>
    </div>
  {:else}
    {#each messages as message (message.id)}
      {#if message instanceof Message}
        <BubbleTransition direction="left">
          <MessageBubble
            answer="{message.type === MessageType.tupleEnd}"
            highlight="{message.type === MessageType.tupleEnd || message.type === MessageType.tupleStart}"
          >
            {message.content}
          </MessageBubble>
        </BubbleTransition>
      {:else if message instanceof PodcastMessage}
        <BubbleTransition direction="left">
          <AudioplayerBubble
            url="{message.episodeUrl}"
            answer="{message.type === MessageType.tupleEnd}"
            highlight="{message.type === MessageType.tupleEnd || message.type === MessageType.tupleStart}"
            showCaption="{!!message.podcastUrl}"
          >
            <svelte:fragment slot="caption">
              {#if message.podcastUrl}
                <a href="{message.podcastUrl}" class="icon-link" target="_blank">
                  <span>Beluister alle episodes van deze podcast.</span>
                  <span class="icon"><Icon name="open_in_new_24dp" /></span>
                </a>
              {/if}
            </svelte:fragment>
          </AudioplayerBubble>
        </BubbleTransition>
      {:else if message instanceof QrMessage}
        <BubbleTransition direction="top">
          <QrBubble
            content="{message.url}"
            helpMessage="Spreek iemand aan om deze QR-code te scannen."
            showCaption="{!!message.refreshable}"
          >
            <svelte:fragment slot="caption">
              {#if message.refreshable}
                <a
                  href="#refresh"
                  role="button"
                  class="icon-link"
                  on:click|preventDefault="{message.refreshable.callback}"
                >
                  <span>{message.refreshable.message}</span>
                  <span class="icon"><Icon name="replay_24dp" /></span>
                </a>
              {/if}
            </svelte:fragment>
          </QrBubble>
        </BubbleTransition>
      {:else if message instanceof Logo}
        <BubbleTransition direction="top">
          <LogoHeader image="{message.img}" />
        </BubbleTransition>
      {/if}
    {/each}
  {/if}
</section>
