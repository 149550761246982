<style>
div :global(canvas) {
  width: 150px;
  height: 150px;
}
</style>

<script>
import { onMount } from 'svelte';
import { QRCode } from 'easyqrcodejs';

export let text;
let node;

onMount(() => {
  const options = {
    text,
    width: 150,
    height: 150,
    dotScale: 0.8,
  };
  // eslint-disable-next-line no-new
  new QRCode(node, options).resize(150, 150);
});
</script>

<div bind:this="{node}"></div>
