<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--grid-size) 0;
}

img {
  max-height: 30px;
}

hr {
  align-self: stretch;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 8px 16px 0;
}
</style>

<script lang="ts">
import type { Img } from '../../game/GameViewModel';

export let image: Img;
</script>

{#if image}
  <div class="container">
    <img
      src="{image.src}"
      srcset="{image.srcSet}"
      width="{image.dimensions.width}"
      height="{image.dimensions.height}"
      alt="{image.alt}"
    />
    <hr />
  </div>
{/if}
