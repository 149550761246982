import App from './components/App.svelte';
import { logging } from './logging';
import { elementScrollIntoViewPolyfill } from 'seamless-scroll-polyfill';
// enable polyfill to enable smooth scrolling in Safari for element.scrollIntoView()
elementScrollIntoViewPolyfill();
// Capture unhandled promise rejections
window.onunhandledrejection = (e) => {
    logging.error(`Unhandled promise rejection; Reason: ${JSON.stringify(e.reason)}`);
    return false;
};
// Capture uncaught exceptions
window.onerror = (msg, url, lineNo, colNo, error) => {
    logging.error(`Msg: ${msg}; URL: ${url}; Line: ${lineNo} Col: ${colNo}; Err object: ${JSON.stringify(error)}`, true);
    return false;
};
const app = new App({
    target: document.body,
    props: {},
});
export default app;
