import { analytics } from './analytics';
const error = (errorDescription, fatal = false) => {
    analytics.logException(errorDescription, fatal);
    console.error(errorDescription);
};
export const logging = {
    /**
     * Logs an error to the console and server.
     */
    error,
};
