<!--
  Poor man's full-screen modal:
  - must be added to the main app component at the bottom (so it's on top of everything)
  - can contain any content 
-->
<style>
.modal-backdrop {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
}

.modal {
  --top-margin: calc(4 * var(--grid-size));
  border-radius: 8px 8px 0 0;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.4);
  height: calc(100% - (var(--top-margin) + var(--page-padding)));
  margin-top: var(--top-margin);
  position: absolute;
  background: white;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;
  overflow-y: auto;
  padding-top: var(--page-padding);
  padding-bottom: var(--page-padding);
}
</style>

<script lang="ts">
import { fade, fly } from 'svelte/transition';
import { cubicOut } from 'svelte/easing';

export let isOpen = false;
</script>

{#if isOpen}
  <div class="modal-backdrop" transition:fade></div>
  <div class="modal" transition:fly="{{ y: 200, duration: 250, easing: cubicOut }}">
    <slot />
  </div>
{/if}
