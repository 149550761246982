<script lang="ts">
import { fly } from 'svelte/transition';

export let direction: 'left' | 'right' | 'bottom' | 'top' | 'none' = 'left';

const flyPixelsX: number = (() => {
  switch (direction) {
    case 'left':
      return -20;
    case 'right':
      return 20;
    default:
      return 0;
  }
})();

const flyPixelsY: number = (() => {
  switch (direction) {
    case 'top':
      return -20;
    case 'bottom':
      return 20;
    default:
      return 0;
  }
})();
</script>

<div
  in:fly="{{ x: flyPixelsX, y: flyPixelsY, duration: 1000 }}"
  out:fly="{{ x: flyPixelsX, y: flyPixelsY, duration: 300 }}"
>
  <slot />
</div>
