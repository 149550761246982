<style>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1;
  overflow: auto;
}

main {
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);
}
</style>

<script lang="ts">
import IntroPage from './IntroPage.svelte';
import Header from './Header.svelte';
import { parseUrl } from '../../urlTools';
import Modal from './Modal.svelte';
import { gdpr } from '../../gdprStore';
import { fetchGame } from '../../api/api';
import { GameViewModel } from '../../game/GameViewModel';
import ChatBox from './ChatBox.svelte';
import { onMount } from 'svelte';
import { analytics } from '../../analytics';
import { logging } from '../../logging';

const thisUrl = parseUrl();

let showIntro = !gdpr.isChecked && !thisUrl.isAnswer;
let game: GameViewModel | null = null;
let errorMessage: string | null = null;

onMount(() => {
  // TODO: line below should be user triggered
  thisUrl.isAnswer && gdpr.setChecked(true);
  thisUrl.isAnswer ? analytics.events.handOver() : analytics.events.appOpen();
});

$: {
  // Start game as soon as it is available and the intro is gone (meaning gdpr is OK - or - will be handled by the game)
  if (game && !showIntro) {
    game.start();
  }
}

if (thisUrl.locationId === null) {
  errorMessage = 'Geen locatie opgegeven. Je kan deze app alleen gebruiken door een QR-code te scannen.';
} else {
  fetchGame(thisUrl.locationId)
    .then((fetchedGame) => {
      game = new GameViewModel(
        thisUrl.locationId!,
        fetchedGame,
        thisUrl.isAnswer ? 'responder' : 'inquirer',
        thisUrl.questionIndex
      );
    })
    .catch((error) => {
      errorMessage = error ?? error.toString();
      logging.error(`Failed to GET game for ${thisUrl.locationId} : ${errorMessage}`, false);
    });
}

const onDismissIntro = () => {
  gdpr.setChecked(true);
  showIntro = false;
};
</script>

<div class="container">
  <Header
    on:help="{() => {
      showIntro = true;
    }}"
  />

  <main>
    {#if errorMessage}
      <h3>Oeps.. er ging iets mis.</h3>
      <p>
        {errorMessage}
      </p>
    {:else if game}
      <ChatBox game="{game}" />
    {/if}
  </main>
</div>

<Modal isOpen="{showIntro}">
  <IntroPage on:dismiss="{onDismissIntro}" />
</Modal>
