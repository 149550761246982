<style>
header {
  padding-left: var(--page-padding);
  padding-right: var(--page-padding);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.icon-btn {
  cursor: pointer;
  padding: 4px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 100px;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.4);
}

.icon-btn:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
}

.icon-btn:focus {
  box-shadow: 0 0 0 2px #ffffff inset;
}

.icon-btn:not(:disabled):active {
  background-color: rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.4);
}
</style>

<script lang="ts">
import { createEventDispatcher } from 'svelte';
import Icon from './Icon.svelte';

const dispatch = createEventDispatcher();

function onHelpButtonClicked() {
  dispatch('help');
}
</script>

<header>
  <h1 class="title">Ijsbreker</h1>
  <a class="icon-btn" on:click|preventDefault="{onHelpButtonClicked}" role="button" href="/" aria-label="Help">
    <Icon name="help_outline_24dp" size="1.5em" />
  </a>
</header>
