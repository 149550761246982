<script lang="ts">
import { parseUrl } from '../urlTools';
import GameIndex from './game/GameIndex.svelte';
import Index from './site/Index.svelte';

const thisUrl = parseUrl();
</script>

{#if thisUrl.isGame}
  <GameIndex />
{:else}
  <Index />
{/if}
