<style>
.frame {
  position: relative;
  width: 16rem;
  height: 30rem;
  background: white;
  box-shadow: 0.7px 0.7px 2.2px rgba(0, 0, 0, 0.02), 1.6px 1.6px 5.3px rgba(0, 0, 0, 0.028),
    3px 3px 10px rgba(0, 0, 0, 0.035), 5.4px 5.4px 17.9px rgba(0, 0, 0, 0.042), 10px 10px 33.4px rgba(0, 0, 0, 0.05),
    24px 24px 80px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  overflow: hidden;
  border: 6px solid rgba(0, 0, 0, 0.8);
  box-sizing: border-box;
}

.inset {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>

<div class="frame">
  <slot />
  <div class="inset"></div>
</div>
