const getTimelyGreeting = () => {
    const currentHour = new Date().getHours();
    return currentHour >= 4 && currentHour < 12
        ? 'Goeie morgen'
        : currentHour >= 12 && currentHour <= 17
            ? 'Goeie middag'
            : currentHour > 17 || currentHour < 4
                ? 'Goeie avond'
                : 'Welkom';
};
export const renderTemplate = (template) => {
    return template.replace('{timelyGreeting}', getTimelyGreeting);
};
export const readingTime = (text) => {
    const charactersPerMinute = 2000;
    const typingTimeMillis = (text.length / charactersPerMinute) * 60000;
    return Math.round(typingTimeMillis);
};
