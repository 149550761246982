<style>
.bubble-wrap {
  display: flex;
  flex-direction: column;
  margin: var(--grid-size) 0;
}

/* Decrease the margin when a caption is present. */
.bubble-wrap.with-caption {
  margin-bottom: 0;
}

.bubble-wrap.answer {
  margin-top: 0;
}

.bubble {
  background: rgba(0, 0, 0, 0.1);
  font-size: 1.4em;
  border-radius: 22px 22px 22px 4px;
  padding: 8px 16px;
}

.bubble.answer {
  border-radius: 4px 22px 22px 22px;
}

.bubble.read {
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.bubble.highlight {
  font-weight: 600;
  background: var(--primary-color-lighter);
}

.caption {
  align-self: center;
  margin-top: 4px;
}

/* Give links a slightly larger touch target */
.caption :global(a) {
  position: relative;
  padding: var(--grid-size);
  margin: calc(-1 * var(--grid-size));
}
</style>

<script lang="ts">
export let answer = false;
export let read = false;
export let highlight = false;
export let showCaption = false; // workaround for not being able to conditionally pass in slot data from the parent; a $$slots.caption check would suffice in case to check whether to show the slot content.
</script>

<div class="bubble-wrap" class:with-caption="{showCaption}" class:answer>
  <div class="bubble" class:answer class:read class:highlight>
    <slot />
  </div>
  {#if showCaption}
    <div class="caption">
      <slot name="caption" />
    </div>
  {/if}
</div>
