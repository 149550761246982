<style>
.icon {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  transition: 300ms;
}
</style>

<script>
export let name;
export let size = '1em'; // adjust to font-size
</script>

<svg xmlns="http://www.w3.org/2000/svg" class="icon" style="width: {size}; height:{size};">
  <use xlink:href="/materialiconsprites.svg#{name}"></use>
</svg>
