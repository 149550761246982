<style>
</style>

<script lang="ts">
import BaseBubble from './BaseBubble.svelte';

export let answer = false;
export let read = false;
export let highlight = false;
export let showCaption = false;
</script>

<BaseBubble answer="{answer}" read="{read}" highlight="{highlight}" showCaption="{showCaption}">
  <slot />
  <slot name="caption" slot="caption" />
</BaseBubble>
