/*
URL structure: http://../?l=<location-id>;q=<question-index>
*/
const LOCATION_PARAMNAME = 'l';
const QUESTION_PARAMNAME = 'q';
const SESSIONID_PARAMNAME = 's';
/**
 * Parses the current location/URL (window.location).
 */
export const parseUrl = () => {
    var _a;
    const urlParams = new URLSearchParams(window.location.search);
    const rawQuestionId = urlParams.get(QUESTION_PARAMNAME);
    return {
        isAnswer: !!(urlParams.get(LOCATION_PARAMNAME) && urlParams.get(QUESTION_PARAMNAME)),
        locationId: urlParams.get(LOCATION_PARAMNAME),
        questionIndex: rawQuestionId == null ? null : (_a = Number.parseInt(rawQuestionId)) !== null && _a !== void 0 ? _a : null,
        sessionId: urlParams.get(SESSIONID_PARAMNAME),
        isGame: !(urlParams.get(LOCATION_PARAMNAME) === null),
    };
};
/**
 * Constructs a URL that points to the given assignment-index at the given location.
 * @param locationId The location-id that points to a specific game.
 * @param assignmentIndex A 0-based question number
 * @param sessionId Id of the game-session as passed by the initiator.
 */
export const constructUrl = (locationId, assignmentIndex, sessionId) => {
    const loc = window.location;
    return `${loc.protocol}//${loc.host}${loc.pathname}?l=${locationId}&q=${assignmentIndex}&s=${sessionId}`;
};
/**
 * True if the current URL is the production URL; false if not (use to discriminate between local(host)/dev testing and
 * the production environment.
 */
export const isProductionUrl = () => window.location.hostname.includes('ijsbreker.app');
