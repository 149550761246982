<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.container {
  --padding: 2rem;
  max-width: 1024px;
  margin: auto;
}

em {
  font-style: normal;
  font-weight: 600;
  color: var(--primary-color-dark);
}

h1.title {
  margin: 0;
}

.headline {
  font-size: 3em;
  line-height: 1.4em;
  margin-bottom: 0.7em;
}

h2 {
  text-align: center;
  color: var(--secondary-color);
  margin-top: 5rem;
  font-weight: 400;
}

header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding);
}

main {
}

.flex-row.big-frame {
  line-height: 1.6em;
  margin-bottom: 8rem;
}

section {
  margin: 3rem 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  padding: 0 var(--padding);
}

.flex-row > * {
  flex: 1;
  margin-bottom: 2em;
}

.features-table {
  min-width: 15em;
}

a.btn {
  --color: rgba(0, 0, 0, 0.87);
  position: relative;
  display: inline-block;
  padding: 0.5em 1em;
  text-decoration: none;
  color: var(--color);
  border: 1px solid var(--color);
}

a.btn:hover {
  background-color: var(--color);
  color: white;
}

.frames {
  position: relative;
  perspective-origin: left;
  perspective: 1000px;
}

.frames > * {
  min-width: 350px;
}

.frame-1 {
  transform: rotateY(-6deg) translateZ(-60px);
  margin-left: 1rem;
}

.frame-2 {
  transform: rotateY(7deg);
  position: absolute;
  right: -5rem;
  top: 0;
}

.frame-image {
  width: 100%;
  height: 100%;
}

tr {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
}

tr:last-child {
  box-shadow: none;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  border-top: 1px dotted rgba(0, 0, 0, 0.1);
  padding-top: var(--padding);
  padding-bottom: var(--padding);
  margin: 5rem calc(2 * var(--padding)) 2rem;
}

.try {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.try > * {
  margin: 0 2rem;
}

.pricing-table {
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1em;
  min-width: 300px;
  max-width: 350px;
}

.price {
  text-align: right;
}
</style>

<script lang="ts">
import DeviceFrame from './DeviceFrame.svelte';
import Icon from '../game/Icon.svelte';
import QR from '../bubbles/QR.svelte';

const scroll = (id: string) => {
  const target = document.getElementById(id);
  if (target) {
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  }
};
</script>

<div class="container">
  <header>
    <h1 class="title">IJSBREKER</h1>
    <a href="mailto:hallo@ijsbreker.app" class="btn">contact</a>
  </header>

  <main>
    <section class="big-frame flex-row">
      <div class="left">
        <div class="headline">Geeft je een zetje voor een échte ontmoeting.</div>
        <p>
          Ijsbreker geeft je via een QR-code een laagdrempelige aanzet om een gesprek aan te knopen met een
          voorbijganger, een date, een dorpsgenoot of een collega.
        </p>

        <p>
          Past perfect bij <em>babbelbanken</em> / <em>bezienswaardigheden</em> / <em>events</em>. Voor
          <em>steden en gemeenten</em>, <em>bedrijven</em>, andere organisaties.
        </p>

        <p>
          <a class="btn" href="#try" on:click|preventDefault="{() => scroll('try')}"> Probeer zelf... </a>
        </p>
      </div>

      <div class="frames">
        <div class="frame-1">
          <DeviceFrame>
            <img class="frame-image" src="/site-resources/sample-left-2x.png" alt="Screenshot eerste scan" />
          </DeviceFrame>
        </div>
        <div class="frame-2">
          <DeviceFrame>
            <img class="frame-image" src="/site-resources/sample-right-2x.png" alt="Screenshot tweede scan" />
          </DeviceFrame>
        </div>
      </div>
    </section>

    <h2>Voorbeelden</h2>
    <section id="try" class="try">
      <div class="try-sample">
        <QR text="https://ijsbreker.app/?l=~textsample&r=site" />
        <div>Vraag/antwoord</div>
      </div>
      <div class="try-sample">
        <QR text="https://ijsbreker.app/?l=~podcastsample&r=site" />
        <div>Podcast</div>
      </div>
    </section>

    <h2>Mogelijkheden | prijs</h2>
    <section class="features flex-row">
      <table class="features-table">
        <tr>
          <td>Eigen logo</td>
          <td>
            <Icon name="check_24dp" />
          </td>
        </tr>
        <tr>
          <td>Gepersonaliseerde openingsberichten</td>
          <td>
            <Icon name="check_24dp" />
          </td>
        </tr>
        <tr>
          <td>Vraag/antwoord interactie</td>
          <td>
            <Icon name="check_24dp" />
          </td>
        </tr>
        <tr>
          <td>Podcast (samen luisteren)</td>
          <td>
            <Icon name="check_24dp" />
          </td>
        </tr>
        <tr>
          <td>Analytics</td>
          <td>
            <Icon name="check_24dp" />
          </td>
        </tr>
        <tr>
          <td>Persoonlijke ondersteuning</td>
          <td>
            <Icon name="check_24dp" />
          </td>
        </tr>
        <tr>
          <td colspan="2">Iets anders in gedachten? Laat het ons weten</td>
        </tr>
      </table>
      <div>
        <table class="pricing-table">
          <tr>
            <td>Setup + 1 locatie</td>
            <td class="price"> €40/mnd</td>
          </tr>
          <tr>
            <td>Per bijkomende locatie</td>
            <td class="price"> €20/mnd</td>
          </tr>
          <tr>
            <td colspan="2" style="font-size: 90%; padding-top:0.5em;">
              Dit zijn <strong>richtprijzen</strong>. Voor vrijwilligersorganisaties en niet-commerciële initiatieven
              zijn we gratis.
              <a href="mailto:hallo@ijsbreker.app">Contacteer me</a>.
            </td>
          </tr>
        </table>
      </div>
    </section>
  </main>

  <footer>
    <div style="margin-bottom: 16px;">
      Gemaakt door <a href="https://www.instagram.com/bertvh/">Bert Vanhooff</a>, in
      <a href="https://www.artenova2800.be">Artenova</a>.
    </div>

    <Icon name="favorite_border_24dp" />
  </footer>
</div>
