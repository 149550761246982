/**
 * A simple FIFO
 */
export class FunQueue {
    constructor() {
        this.queue = [];
        this._isExecuting = false;
    }
    push(fun) {
        if (this._isExecuting) {
            fun();
        }
        else {
            this.queue.push(fun); // add to the end
        }
    }
    startExecuting() {
        this._isExecuting = true;
        while (this.queue.length > 0) {
            this.queue.shift()(); // remove from the front
        }
    }
    stopExecuting() {
        this._isExecuting = false;
    }
    get isExecuting() {
        return this._isExecuting;
    }
}
