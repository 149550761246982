var _a;
import { isProductionUrl, parseUrl } from './urlTools';
import { nanoid } from 'nanoid';
import mixpanel from 'mixpanel-browser';
import { FunQueue } from './FunQueue';
let isAnalyticsEnabled = false;
const mixPanelQueue = new FunQueue();
const sessionId = (_a = parseUrl().sessionId) !== null && _a !== void 0 ? _a : nanoid(8);
// Switch between ijsbreker and ijsbreker-dev tracking id
const mixpanelTrackingID = isProductionUrl() ? 'dae4a624ebeecac01a5c2b29df99ad53' : '8d6d3e698286fa5474eb89adf831f462';
/**
 * Call this method to enable tracking AFTER the user has given consent.
 */
const enable = () => {
    if (isAnalyticsEnabled) {
        return;
    }
    if (!isProductionUrl()) {
        console.info('%cAnalytics will log to DEV property (not a production environment)', 'color:blue');
    }
    mixpanel.init(mixpanelTrackingID, {
        disable_cookie: true,
        disable_persistence: true,
        debug: !isProductionUrl(),
    });
    mixPanelQueue.startExecuting();
    isAnalyticsEnabled = true;
};
/**
 * Logs an exception to analytics - see https://developers.google.com/analytics/devguides/collection/gtagjs/exceptions
 */
const logException = (description, fatal) => {
    console.error(`Logging exception → analytics  |  description: ${description}); fatal: ${fatal}`);
    if (!isAnalyticsEnabled) {
        console.warn('Analytics not enabled - exception not logged.');
        return;
    }
    mixpanel.track('error', {
        errorDescription: description,
        errorFatal: fatal,
    });
};
var Event;
(function (Event) {
    Event["appOpen"] = "app_open";
    Event["handOver"] = "hand_over";
    Event["mediaPlay"] = "media_play";
})(Event || (Event = {}));
const mixpanelProperties = {
    distinct_id: sessionId,
    location_id: parseUrl().locationId,
};
const sendAppOpenEvent = () => {
    mixPanelQueue.push(() => mixpanel.track(Event.appOpen, mixpanelProperties));
};
const sendHandoverEvent = () => {
    mixPanelQueue.push(() => mixpanel.track(Event.handOver, mixpanelProperties));
};
const sendAudioPlayEvent = () => {
    mixPanelQueue.push(() => mixpanel.track(Event.mediaPlay, mixpanelProperties));
};
export const analytics = {
    enable,
    logException,
    /**
     * This game's instance (not super-unique) session-id. It is taken from the
     * URL if present OR generated if not.
     */
    sessionId,
    events: {
        appOpen: sendAppOpenEvent,
        handOver: sendHandoverEvent,
        audioPlay: sendAudioPlayEvent,
    },
};
