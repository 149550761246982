<style>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr {
  --border-width: 8px;
  position: relative;
  background: white;
  border: var(--border-width) solid var(--primary-color-light);
  border-radius: 24px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin: var(--grid-size) 0;
}

.qr:before,
.qr:after {
  content: '';
  display: block;
  position: absolute;
  background: white;
  z-index: 1;
}

.qr:before {
  height: 60%;
  top: 20%; /* = (100% - height)/2 */
  width: calc(100% + calc(2 * var(--border-width)));
  left: calc(-1 * var(--border-width));
}

.qr:after {
  width: 60%;
  left: 20%; /* = (100% - height)/2 */
  height: calc(100% + calc(2 * var(--border-width)));
  top: calc(-1 * var(--border-width));
}

.qr > div {
  position: relative;
  z-index: 10;
}
</style>

<script lang="ts">
import QR from './QR.svelte';

export let content: string;
export let helpMessage: string;
export let showCaption = false; // workaround for not being able to conditionally pass in slot data from the parent; a $$slots.caption check would suffice in case to check whether to show the slot content.

const clickHandler = () => {
  alert(helpMessage);
  console.log(content);
};
</script>

<div class="wrapper">
  <div class="qr" on:click="{clickHandler}">
    <div>
      <QR text="{content}" />
    </div>
  </div>
  {#if showCaption}
    <div class="caption">
      <slot name="caption" />
    </div>
  {/if}
</div>
