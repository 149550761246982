import { writable, get } from 'svelte/store';
import { DelayedQueue } from './DelayedQueue';
import { readingTime, renderTemplate } from '../textTools';
import { constructUrl } from '../urlTools';
import { analytics } from '../analytics';
export var MessageType;
(function (MessageType) {
    MessageType[MessageType["title"] = 0] = "title";
    MessageType[MessageType["normal"] = 1] = "normal";
    MessageType[MessageType["tupleStart"] = 2] = "tupleStart";
    MessageType[MessageType["tupleEnd"] = 3] = "tupleEnd";
})(MessageType || (MessageType = {}));
class Refreshable {
    constructor(callback, message) {
        this.callback = callback;
        this.message = message;
    }
}
export class Message {
    constructor(id, content, type, options) {
        var _a;
        this.refreshable = null;
        this.id = id;
        this.groupId = options === null || options === void 0 ? void 0 : options.groupId;
        this.content = content;
        this.type = type;
        this.refreshable = (_a = options === null || options === void 0 ? void 0 : options.refreshable) !== null && _a !== void 0 ? _a : null;
    }
}
export class PodcastMessage {
    constructor(id, title, episodeUrl, podcastUrl, type, options) {
        this.id = id;
        this.groupId = options === null || options === void 0 ? void 0 : options.groupId;
        this.title = title;
        this.episodeUrl = episodeUrl;
        this.podcastUrl = podcastUrl;
        this.type = type;
    }
}
export class QrMessage {
    constructor(id, url, options) {
        var _a;
        this.refreshable = null;
        this.id = id;
        this.groupId = options === null || options === void 0 ? void 0 : options.groupId;
        this.url = url;
        this.refreshable = (_a = options === null || options === void 0 ? void 0 : options.refreshable) !== null && _a !== void 0 ? _a : null;
    }
}
export class Logo {
    constructor(id, img, type, options) {
        this.id = id;
        this.groupId = options === null || options === void 0 ? void 0 : options.groupId;
        this.img = img;
        this.type = type;
    }
}
function isTextResponse(value) {
    return value.message !== undefined;
}
function isPodcastResponse(value) {
    return value.episodeUrl !== undefined;
}
export class GameViewModel {
    constructor(locationId, game, role = 'inquirer', assignmentIndex = null) {
        this.defaultDelay = 400;
        this._messages = writable([]);
        this._queue = new DelayedQueue();
        this.isStarted = false;
        this.messages = Object.freeze({
            subscribe: this._messages.subscribe,
        });
        this.nextMessageId = (() => {
            let messageId = 0;
            return () => messageId++;
        })();
        this.nextGroupId = (() => {
            let groupId = 0;
            return () => groupId++;
        })();
        this.locationId = locationId;
        this.name = game.metadata.name;
        this.location = game.metadata.location;
        this.logo = game.metadata.logo
            ? {
                dimensions: game.metadata.logo.dimensions,
                src: game.metadata.logo['1x'],
                srcSet: `${game.metadata.logo['1x']} 1x, ${game.metadata.logo['2x']} 2x`,
                alt: 'logo',
            }
            : null;
        this.intro = (() => {
            switch (role) {
                case 'inquirer':
                    return game.intro.left;
                case 'responder':
                    return game.intro.right;
                default:
                    return [];
            }
        })();
        this.assignments = game.assignments;
        this.assignmentIndex = assignmentIndex !== null && assignmentIndex !== void 0 ? assignmentIndex : Math.floor(Math.random() * this.assignments.length); // random start assignment
        this.role = role;
    }
    nextAssignment() {
        this.assignmentIndex = (this.assignmentIndex + 1) % this.assignments.length;
    }
    addMessage(message, delay) {
        this._queue.addTask(() => {
            this._messages.update((prevArray) => [...prevArray, message]);
        }, delay);
    }
    removeMessageImmediate(messageId) {
        this._messages.update((prevArray) => prevArray.filter((el) => el.id !== messageId));
    }
    /**
     * Removes all messages of the given group, starting with the last message of the group.
     */
    removeMessageGroup(groupId) {
        if (groupId === undefined) {
            return;
        }
        get(this._messages)
            .filter((msg) => msg.groupId === groupId)
            .map((msg) => msg.id)
            .reverse()
            .forEach((id) => this._queue.addTask(() => this.removeMessageImmediate(id), this.defaultDelay));
    }
    get currentAssignmentIndex() {
        return this.assignmentIndex;
    }
    start() {
        if (this.isStarted) {
            return;
        }
        this.isStarted = true;
        this.logo && this.addMessage(new Logo(this.nextMessageId(), this.logo, MessageType.title), 0);
        let nextDelay = 1000; // first message delay
        this.intro.forEach((txt) => {
            this.addMessage(new Message(this.nextMessageId(), renderTemplate(txt.message), MessageType.normal), nextDelay);
            nextDelay = readingTime(txt.message);
        });
        this.addAssignment(this.nextGroupId(), nextDelay);
    }
    refreshAssignment(groupId) {
        this.nextAssignment();
        this.removeMessageGroup(groupId);
        // TODO; minimal delay should be as large as the minimal animation
        this.addAssignment(this.nextGroupId(), this.defaultDelay, true);
    }
    addAssignment(groupId, initialDelay, fast = false) {
        const assignmentText = this.assignments[this.assignmentIndex].left;
        const assignmentId = this.nextMessageId();
        this.addMessage(new Message(assignmentId, assignmentText, MessageType.tupleStart, { groupId }), initialDelay);
        const nextDelay = readingTime(assignmentText);
        switch (this.role) {
            case 'inquirer':
                this.addMessage(this.qrMessage(groupId), fast ? this.defaultDelay : 2000);
                break;
            case 'responder':
                this.addMessage(this.revealMessage(), nextDelay);
                break;
            default:
        }
    }
    qrMessage(groupId) {
        const url = constructUrl(this.locationId, this.assignmentIndex, analytics.sessionId);
        const id = this.nextMessageId();
        return new QrMessage(id, url, {
            groupId: groupId,
            refreshable: new Refreshable(() => {
                this.refreshAssignment(groupId);
            }, 'Andere ijsbreker'),
        });
    }
    revealMessage() {
        var _a;
        const firstResponderText = this.assignments[this.assignmentIndex].right;
        if (isTextResponse(firstResponderText)) {
            return new Message(this.nextMessageId(), firstResponderText.message, MessageType.tupleEnd);
        }
        else if (isPodcastResponse(firstResponderText)) {
            return new PodcastMessage(this.nextMessageId(), firstResponderText.title, firstResponderText.episodeUrl, (_a = firstResponderText.podcastUrl) !== null && _a !== void 0 ? _a : null, MessageType.tupleEnd);
        }
        return new Message(this.nextMessageId(), 'Oops.. foutje', MessageType.normal);
    }
    fastForward() {
        this._queue.fastForward = true;
    }
}
