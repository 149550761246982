<style>
section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1.title {
  margin-bottom: 0;
}

.tagline {
  color: var(--text-color-disabled);
  padding: 0 24px;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3em;
  text-align: center;
}

.help {
  width: 100%;
  background: rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3em;
}

ol {
  list-style: none;
  counter-reset: list-counter;
  text-align: left;
  padding: 0 24px;
  --size: 1.3em;
  --bullet-margin: 0.5em;
  text-indent: calc(-1 * (var(--size) + var(--bullet-margin)));
  margin: 0 0 0 calc((1 * (var(--size)) + var(--bullet-margin)));
}

ol li {
  counter-increment: list-counter;
  margin-bottom: 0.4em;
  margin-top: 0.4em;
}

ol li:first-child {
  margin-top: 0.8em;
}

ol li:last-child {
  margin-bottom: 0.8em;
}

ol li::before {
  text-indent: 0;
  content: counter(list-counter);
  color: var(--primary-color);
  box-sizing: border-box;
  border: 1px solid var(--primary-color);
  border-radius: 100px;
  display: inline-block;
  text-align: center;

  margin-right: var(--bullet-margin);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
}

.info {
  font-size: 90%;
  margin-top: calc(2 * var(--grid-size));
  text-align: center;
}
</style>

<script lang="ts">
import { createEventDispatcher } from 'svelte';

const email = 'hallo@ijsbreker.app';

const dispatch = createEventDispatcher();

function onDismissButtonClicked() {
  dispatch('dismiss');
}
</script>

<section>
  <div class="title-container">
    <h1 class="title">
      <span style="display: inline-block">ijsbreker</span>
    </h1>
    <div class="tagline">geeft je een zetje voor een babbel met een onbekende.</div>
  </div>

  <div class="help">
    <ol>
      <li>Jij krijgt een vraag / raadsel / podcast te zien.</li>
      <li>Het vervolg zit verscholen in een nieuwe QR-code.</li>
      <li>
        Spreek een voorbijganger aan om je scherm te scannen en ontdek het samen.<br />
        <span>TIP: geef eerst een woordje uitleg.</span>
      </li>
    </ol>
  </div>

  <button class="btn-action" on:click="{onDismissButtonClicked}"> Yes, ik ben er klaar voor!</button>

  <div class="info">
    <a href="./privacy/index.html" target="_blank">privacybeleid</a> &#183; <a href="mailto:{email}">{email}</a>
  </div>
</section>
